import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ServicesDetails = ({ 
    client, 
    services, 
    getTouch, 
    details, 
    faqData 
}) => {
    const [isActive, setIsActive] = useState({ key: null });

    const handleToggle = (key) => {
        setIsActive(isActive.key === key ? { key: null } : { key });
    };

    return (
        <section className="services-details">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-5">
                        <div className="services-details__left">
                            <div className="services-details__services-box">
                                <h3 className="services-details__services-list-title">Our Services</h3>
                                <ul className="services-details__service-list list-unstyled">
                                    {services.map((service, index) => (
                                        <li key={index} className={service.active ? 'active' : ''}>
                                            <div className="icon">
                                                <span className="icon-dabble-arrow" />
                                            </div>
                                            <p>
                                                <Link to={service.link}>{service.name}</Link>
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-7">
                        <div className="services-details__right">
                            <h3 className="services-details__title-1">{details.title}</h3>
                            <p className="services-details__text-1">{details.text1}</p>
                            <div className="services-details__img-1 mb-5">
                                <img src={details.image1} alt="" />
                            </div>
                            <div
                                className="services-details__text-2"
                                dangerouslySetInnerHTML={{ __html: details.text2 }}
                            />
                            <div className="services-details__faq">
                                <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
                                    {faqData.map((faq, index) => (
                                        <div key={index} className={isActive.key === index ? "accrodion active" : "accrodion"} onClick={() => handleToggle(index)}>
                                            <div className="accrodion-count" />
                                            <div className="accrodion-title">
                                                <h4>{faq.question}</h4>
                                            </div>
                                            <div className={isActive.key === index ? "accrodion-content current" : "accrodion-content"}>
                                                <div className="inner">
                                                    <p>{faq.answer}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="blog-details__pagenation-box">
                                    <ul className="list-unstyled blog-details__pagenation">
                                        <li>
                                            <div className="icon">
                                                <Link to={details.prevLink}>
                                                    <span className="icon-arrow-left" />
                                                </Link>
                                            </div>
                                            <p>{details.prevText}</p>
                                        </li>
                                        <li>
                                            <p>{details.nextText}</p>
                                            <div className="icon">
                                                <Link to={details.nextLink}>
                                                    <span className="icon-arrow-right" />
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesDetails;
