import React from "react";
import CounterTwo from "../../components/HomeTwo/CounterTwo";
import counterImage from "../../assets/images/resources/counter-two-img-1.jpg";

const counterData = {
  image: counterImage,
  sinceYear: "Since 2024",
  counters: [
    {
      end: 100,
      unit: "+",
      iconClass: "icon-user",
      text: "Happy costumers",
    },
    {
      end: 500,
      unit: "",
      iconClass: "icon-clock",
      text: "Ontime deliveries",
    },
    {
      end: 900,
      unit: "",
      iconClass: "icon-lift-truck",
      text: "Shipement moved",
    },
    {
      end: 14,
      unit: "+",
      iconClass: "icon-schedule",
      text: "Years of experience",
    },
  ],
};

const Counter = () => {
  return (
    <>
      <CounterTwo {...counterData} />
    </>
  );
};

export default Counter;
