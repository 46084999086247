import React from 'react';
import ProcessOne from '../../components/HomeTwo/ProcessOne';
import processShapeImage from '../../assets/images/shapes/process-one-shape-1.png';

const processOneData = {
    tagline: 'Work Process',
    title: 'Streamlined Logistics <br />Transportation Services for Your Needs',
    processes: [
        {
            shapeImage: processShapeImage,
            title: 'Request Service',
            iconClass: 'icon-customer-service',
            description: 'Start by requesting the service you need through our platform. Provide details about your shipment, including volume, type, and destination.'
        },
        {
            shapeImage: processShapeImage,
            title: 'Service Confirmation',
            iconClass: 'icon-schedule',
            description: 'Once we receive your request, we will confirm the service and provide you with all necessary information, including pricing and estimated delivery times.'
        },
        {
            shapeImage: processShapeImage,
            title: 'Shipment and Delivery',
            iconClass: 'icon-shipping',
            description: 'Our team will handle the shipment with care and efficiency, ensuring that your goods are delivered to the specified destination on time and in perfect condition.'
        }
    ]
};

const Process = () => {
    return (
        <>
            <ProcessOne {...processOneData} />
        </>
    );
};

export default Process;
