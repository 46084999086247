export const generateSubscribeTemplate = ({ clientEmail }) => {
  const html = `
    <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
      <h2 style="color: #333; border-bottom: 2px solid #eee; padding-bottom: 10px;">Newsletter Subscription Confirmation</h2>
      
      <div style="background: #f9f9f9; padding: 20px; border-radius: 5px; margin: 20px 0;">
        <h3 style="color: #444; margin-top: 0;">Subscription Details</h3>
        <p style="margin: 5px 0;"><strong>Email:</strong> ${clientEmail}</p>
        <p style="margin: 15px 0;">Thank you for subscribing to our newsletter! You will receive updates about our services, industry news, and special offers.</p>
      </div>
    </div>
  `;

  return {
    subject: `Newsletter Subscription Confirmation`,
    html,
  };
};

export const generateInternalSubscribeNotificationTemplate = ({
  clientEmail,
}) => {
  const html = `
    <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
      <h2 style="color: #333; border-bottom: 2px solid #eee; padding-bottom: 10px;">New Newsletter Subscription Request</h2>
      
      <div style="background: #f9f9f9; padding: 20px; border-radius: 5px; margin: 20px 0;">
        <h3 style="color: #444; margin-top: 0;">New Subscriber Details</h3>
        <p style="margin: 5px 0;"><strong>Email:</strong> ${clientEmail}</p>
        <p style="margin: 5px 0;"><strong>Date:</strong> ${new Date().toLocaleString()}</p>
      </div>
      
      <p style="color: #666; font-size: 14px;">This is an automated notification for a new newsletter subscription.</p>
    </div>
  `;

  return {
    subject: `New Newsletter Subscriber: ${clientEmail}`,
    html,
  };
};
