import React from 'react';
import ServiceItem from '../../components/Service/ServiceItem';



const ServicesMain = () => {
    const services = [
        { iconClass: 'icon-truck-small', title: 'Less Than Truckload (LTL)', link: '/ltl' },
        { iconClass: 'icon-truck-full', title: 'Full Truckload (FTL)', link: '/ftl' },
        { iconClass: 'icon-fast-delivery', title: 'Expedited Shipping', link: '/expedited' },
    ];
      

    return (
        <React.Fragment>
            <section className="services-page">
                <div className="container">
                    <div className="row">
                        {services.map((service, index) => (
                            <div key={index} className="col-xl-4 col-lg-4">
                                <ServiceItem
                                    iconClass={service.iconClass}
                                    title={service.title}
                                    link={service.link}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default ServicesMain;
