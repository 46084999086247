import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/resources/footer-logo-2.png";

const MobileMenu = ({ handleMobileMenu }) => {
  const [isActive, setIsActive] = useState({
    status: false,
    key: "",
    subMenuKey: "",
  });

  const handleToggle = (key, subMenuKey = "") => {
    if (isActive.key === key && isActive.subMenuKey === subMenuKey) {
      setIsActive({
        status: false,
        key: "",
        subMenuKey: "",
      });
    } else {
      setIsActive({
        status: true,
        key,
        subMenuKey,
      });
    }
  };

  return (
    <>
      <div className="mobile-nav__wrapper">
        <div
          className="mobile-nav__overlay mobile-nav__toggler"
          onClick={handleMobileMenu}
        ></div>
        <div className="mobile-nav__content">
          <span
            className="mobile-nav__close mobile-nav__toggler"
            onClick={handleMobileMenu}
          >
            <i className="fa fa-times"></i>
          </span>

          <div className="logo-box">
            <Link to="/" aria-label="logo image">
              <img src={logo} width="150" alt="Logo" />
            </Link>
          </div>

          <div className="mobile-nav__container">
            <div
              className="collapse navbar-collapse show clearfix"
              id="navbarSupportedContent"
            >
              <ul className="main-menu__list">
                <li
                  className={
                    isActive.key === 1 ? "dropdown current" : "dropdown"
                  }
                >
                  <Link to="/" onClick={handleMobileMenu}>
                    Home
                  </Link>
                </li>
                <li
                  className={
                    isActive.key === 3 ? "dropdown current" : "dropdown"
                  }
                >
                  <Link to="/#" onClick={handleMobileMenu}>
                    Company
                  </Link>
                  <ul
                    style={{
                      display: `${isActive.key === 3 ? "block" : "none"}`,
                    }}
                  >
                    <li>
                      <Link to="/about" onClick={handleMobileMenu}>
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/team" onClick={handleMobileMenu}>
                        Our Team
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" onClick={handleMobileMenu}>
                        Contact
                      </Link>
                    </li>
                  </ul>
                  <div
                    className={
                      isActive.key === 3 ? "dropdown-btn open" : "dropdown-btn"
                    }
                    onClick={() => handleToggle(3)}
                  >
                    <span className="fa fa-angle-right" />
                  </div>
                </li>
                <li
                  className={
                    isActive.key === 4 ? "dropdown current" : "dropdown"
                  }
                >
                  <Link to="/#" onClick={handleMobileMenu}>
                    Services
                  </Link>
                  <ul
                    style={{
                      display: `${isActive.key === 4 ? "block" : "none"}`,
                    }}
                  >
                    <li>
                      <Link to="/services" onClick={handleMobileMenu}>
                        Services Overview
                      </Link>
                    </li>
                    <li>
                      <Link to="/ltl" onClick={handleMobileMenu}>
                        Less Than Truckload (LTL)
                      </Link>
                    </li>
                    <li>
                      <Link to="/ftl" onClick={handleMobileMenu}>
                        Full Truckload (FLT)
                      </Link>
                    </li>
                    <li>
                      <Link to="/expedited" onClick={handleMobileMenu}>
                        Expedited
                      </Link>
                    </li>
                  </ul>
                  <div
                    className={
                      isActive.key === 4 ? "dropdown-btn open" : "dropdown-btn"
                    }
                    onClick={() => handleToggle(4)}
                  >
                    <span className="fa fa-angle-right" />
                  </div>
                </li>
                <li>
                  <Link to="/carrier-service" onClick={handleMobileMenu}>
                    Carrier Service
                  </Link>
                </li>
                <li>
                  <Link to="/get-a-quote" onClick={handleMobileMenu}>
                    Get a Quote
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="fa fa-envelope"></i>
              <Link to="mailto:info@shiprighttransit.com">
                info@shiprighttransit.com
              </Link>
            </li>
            <li>
              <i className="fa fa-phone-alt"></i>
              <Link to="tel:7178853056">717 885 3056</Link>
            </li>
          </ul>
          <div className="mobile-nav__top">
            <div className="mobile-nav__social">
              <Link
                to="https://www.instagram.com/shipright_transit/profilecard/?igsh=OW44MW95MjN0cnNi"
                className="fab fa-instagram"
              ></Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
