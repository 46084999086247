import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeTwo from "./pages/home-2";
import NoPage from "./pages/NoPage";
import About from "./pages/about";
import Services from "./pages/services";
import ServicesCarousel from "./pages/services-carousel";
import Solutions from "./pages/less-than-truckload";
import Logistics from "./pages/expedited";
import Dispatch from "./pages/full-truckload";
import Team from "./pages/team";
import Contact from "./pages/contact";
import GetQuote from "./pages/get-quote";
import CarrierService from "./pages/carrier-service";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<HomeTwo />} />
        <Route path="*" element={<NoPage />} />
        <Route path="services" element={<ServicesCarousel />} />
        <Route path="ltl" element={<Solutions />} />
        <Route path="expedited" element={<Logistics />} />
        <Route path="ftl" element={<Dispatch />} />
        <Route path="about" element={<About />} />
        <Route path="team" element={<Team />} />
        <Route path="services" element={<Services />} />
        <Route path="contact" element={<Contact />} />
        <Route path="get-a-quote" element={<GetQuote />} />
        <Route path="carrier-service" element={<CarrierService />} />
      </Routes>
    </BrowserRouter>
  );
}
