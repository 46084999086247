export const generateCarrierEmailTemplate = ({
  clientName,
  companyName,
  clientEmail,
  phoneNumber,
  carrierMC,
  truckCount,
  message,
}) => {
  const html = `
    <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
      <h2 style="color: #333; border-bottom: 2px solid #eee; padding-bottom: 10px;">New Carrier Service Application</h2>
      
      <div style="background: #f9f9f9; padding: 20px; border-radius: 5px; margin: 20px 0;">
        <h3 style="color: #444; margin-top: 0;">Company Information</h3>
        <p style="margin: 5px 0;"><strong>Company Name:</strong> ${companyName}</p>
        <p style="margin: 5px 0;"><strong>Carrier MC#:</strong> ${carrierMC}</p>
        <p style="margin: 5px 0;"><strong>Number of Trucks:</strong> ${truckCount}</p>
      </div>

      <div style="background: #f9f9f9; padding: 20px; border-radius: 5px; margin: 20px 0;">
        <h3 style="color: #444; margin-top: 0;">Contact Information</h3>
        <p style="margin: 5px 0;"><strong>Contact Name:</strong> ${clientName}</p>
        <p style="margin: 5px 0;"><strong>Email:</strong> ${clientEmail}</p>
        <p style="margin: 5px 0;"><strong>Phone:</strong> ${phoneNumber}</p>
      </div>

      <div style="background: #f9f9f9; padding: 20px; border-radius: 5px; margin: 20px 0;">
        <h3 style="color: #444; margin-top: 0;">Additional Information</h3>
        <p style="margin: 5px 0; white-space: pre-line;">${message}</p>
      </div>
    </div>
  `;

  return {
    subject: `New Carrier Service Application - ${companyName}`,
    html,
  };
};
