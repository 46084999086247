import React, { useState } from "react";
import bannerbg from "../../assets/images/resources/cta-one-bg-img.jpg";
import shapeImageUrl from "../../assets/images/shapes/cta-one-shape-1.png";
import ContactMainImg from "../../assets/images/resources/contact-two-img-1.jpg";
import CtaSection from "../../components/Common/CtaSection";
import GoogleMap from "../home-2/GoogleMap";
import useSendEmail from "../../hooks/useSendEmail";
import useStatusMessage from "../../hooks/useStatusMessage";

const GetQuoteMain = () => {
  const [formData, setFormData] = useState({
    clientName: "",
    clientEmail: "",
    selectedService: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const { sendEmail } = useSendEmail();
  const [status, setStatus] = useStatusMessage();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.selectedService ||
      !formData.clientName ||
      !formData.clientEmail ||
      !formData.message
    ) {
      setStatus({
        success: false,
        message: "Please fill in all required fields.",
      });
      return;
    }

    setIsSubmitting(true);
    setStatus({ success: null, message: "" });

    try {
      const result = await sendEmail(formData, "quote");
      if (result.success) {
        setStatus({
          success: true,
          message: "Quote request sent successfully!",
        });
        setFormData({
          clientName: "",
          clientEmail: "",
          selectedService: "",
          message: "",
        });
      } else {
        throw new Error(result.error || "Failed to send quote request");
      }
    } catch (err) {
      setStatus({
        success: false,
        message: err.message || "Failed to send quote request",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      {/* Contact Two with Quote Form */}
      <section className="contact-two contact-two-custom-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-7">
              <div className="contact-two__left">
                <div className="section-title text-left">
                  <div className="section-title__tagline-box">
                    <span className="section-title__tagline">Get a quote</span>
                  </div>
                  <h2 className="section-title__title">
                    Send us a Request to a Quote of our services
                  </h2>
                </div>

                {status.message && (
                  <div
                    className={`alert ${
                      status.success ? "alert-success" : "alert-danger"
                    }`}
                    style={{
                      padding: "15px",
                      borderRadius: "5px",
                      backgroundColor: status.success ? "#d4edda" : "#f8d7da",
                      color: status.success ? "#155724" : "#721c24",
                      marginBottom: "20px",
                    }}
                  >
                    {status.message}
                  </div>
                )}

                <form
                  onSubmit={handleSubmit}
                  className="contact-form-validated contact-two__form"
                >
                  <div className="contact-two__input-container">
                    <div className="contact-two__input-box">
                      <input
                        type="text"
                        name="clientName"
                        value={formData.clientName}
                        onChange={handleInputChange}
                        placeholder="Enter your name"
                        required
                      />
                    </div>

                    <div className="contact-two__input-box">
                      <input
                        type="email"
                        name="clientEmail"
                        value={formData.clientEmail}
                        onChange={handleInputChange}
                        placeholder="Enter your email"
                        required
                      />
                    </div>

                    <div className="contact-two__input-box">
                      <select
                        name="selectedService"
                        value={formData.selectedService}
                        onChange={handleInputChange}
                        required
                        className={`custom-select-style ${
                          isSelectOpen ? "open" : ""
                        }`}
                        onFocus={() => setIsSelectOpen(true)}
                        onBlur={() => setIsSelectOpen(false)}
                      >
                        <option value="">Select a service</option>
                        <option value="Less Than Truckload (LTL)">
                          Less Than Truckload (LTL)
                        </option>
                        <option value="Full Truckload (FLT)">
                          Full Truckload (FLT)
                        </option>
                        <option value="Expedited">Expedited</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div className="contact-two__input-box text-message-box">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        placeholder="Message"
                        required
                      />
                    </div>

                    <div className="contact-two__btn-box">
                      <button
                        type="submit"
                        className="thm-btn contact-two__btn"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Sending..." : "Get Quote"}
                        <span />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="contact-two__right">
                <div className="contact-two__img">
                  <img src={ContactMainImg} alt="Get in touch" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Two End*/}

      {/*Google Map Start*/}
      <GoogleMap />
      {/*Google Map End*/}

      {/*cta-section */}
      <CtaSection
        breadcrumbsImg={bannerbg}
        shapeImageUrl={shapeImageUrl}
        title="Need any help? Contact us!"
        text="Need help?"
        phoneNumber="(717) 885-3056"
      />
      {/*cta-section end */}
    </React.Fragment>
  );
};

export default GetQuoteMain;
