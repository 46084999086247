import { useState } from "react";
import { generateCarrierEmailTemplate } from "../templates/carrierTemplates";
import { generateServiceQuoteTemplate } from "../templates/servicesTemplate";
import { generateContactFormTemplate } from "../templates/contactFormTemplate";

const API_URL = process.env.REACT_APP_API_URL;

const useSendEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = async (endpoint, data) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/email/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || "Failed to send email");
      }

      setIsLoading(false);
      return { success: true, data: result };
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
      return { success: false, error: err.message };
    }
  };

  const sendEmail = async (formData, type) => {
    if (type === "carrier") {
      const emailTemplate = generateCarrierEmailTemplate(formData);
      return sendRequest(type, emailTemplate);
    } else if (type === "quote") {
      const emailTemplate = generateServiceQuoteTemplate(formData);
      return sendRequest(type, emailTemplate);
    } else if (type === "contact") {
      const emailTemplate = generateContactFormTemplate(formData);
      return sendRequest(type, emailTemplate);
    }
  };

  return {
    sendEmail,
    isLoading,
    error,
  };
};

export default useSendEmail;
