// src/components/TeamMember.js
import React from 'react';

const TeamMember = ({ image, name, position, socialLinks, link }) => {
    return (
        <div className="team-one__single">
            <div className="team-one__img">
                <img src={image} alt={name} />
            </div>
            <div className="team-one__content">
                <h3 className="team-one__title">
                    {name}
                </h3>
                <p className="team-one__sub-title">{position}</p>
                
            </div>
        </div>
    );
};

export default TeamMember;
