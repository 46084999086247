import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuItems from "./MenuItems";
import MobileMenu from "./MobileMenu";
import LogoTwo from "../../../assets/images/resources/logo-2.png";
import { handleNewsletterSubscription } from "../../../utils/newsletterSubscription";

const HeaderStyleTwo = ({ parentMenu, secondParentMenu, activeMenu }) => {
  const [scroll, setScroll] = useState(false);
  const [isMobileMenu, setMobileMenu] = useState(false);
  const [isSidebar, setIsSidebar] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState({
    show: false,
    type: null,
    message: "",
  });

  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
    if (!isMobileMenu) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }
  };

  const handleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    const email = e.target.EMAIL.value;

    try {
      const result = await handleNewsletterSubscription(email);

      if (result.success) {
        setSubscriptionStatus({
          show: true,
          type: "success",
          message: "✓ Successfully subscribed to our newsletter!",
        });
        e.target.reset();

        // Hide success message after 5 seconds
        setTimeout(() => {
          setSubscriptionStatus({ show: false, type: null, message: "" });
        }, 5000);
      }
    } catch (error) {
      setSubscriptionStatus({
        show: true,
        type: "error",
        message: "× Error sending subscription. Please try again.",
      });

      // Hide error message after 4 seconds
      setTimeout(() => {
        setSubscriptionStatus({ show: false, type: null, message: "" });
      }, 4000);
    }
  };

  const NewsletterForm = () => (
    <div className="header-widget__newsletter">
      <h3 className="header-widget__newsletter-text">
        Subscribe to Our Newsletter
      </h3>
      <form
        className="header-widget__newsletter-form"
        onSubmit={handleSubscribe}
      >
        <div className="header-widget__newsletter-form-input-box">
          <input
            type="email"
            placeholder="Your e-mail"
            name="EMAIL"
            required
            className="w-full"
          />
          <button
            type="submit"
            className="header-widget__newsletter-btn"
            disabled={subscriptionStatus.show}
          >
            <span className="icon-paper-plane"></span>
          </button>
        </div>
        {subscriptionStatus.show && (
          <div
            className={`
              text-sm mt-2 font-medium
              ${
                subscriptionStatus.type === "success"
                  ? "text-green-500"
                  : "text-red-500"
              }
              transition-opacity duration-300
            `}
            role="alert"
          >
            {subscriptionStatus.message}
          </div>
        )}
      </form>
    </div>
  );

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <React.Fragment>
      <header className={`main-header-two ${scroll ? "fixed-header" : ""}`}>
        <nav className="main-menu main-menu-two">
          <div className="main-menu-two__wrapper">
            <div className="container">
              <div className="main-menu-two__wrapper-inner">
                <div className="main-menu-two__left">
                  <div className="main-menu-two__logo">
                    <Link to="/">
                      <img src={LogoTwo} alt="Logo" />
                    </Link>
                  </div>
                  <div className="main-menu-two__main-menu-box">
                    <Link
                      to="#"
                      className="mobile-nav__toggler"
                      onClick={handleMobileMenu}
                    >
                      <i className="fa fa-bars" />
                    </Link>
                    <ul className="main-menu__list">
                      <MenuItems
                        parentMenu={parentMenu}
                        secondParentMenu={secondParentMenu}
                        activeMenu={activeMenu}
                      />
                    </ul>
                  </div>
                </div>
                <div className="main-menu-two__right">
                  <div className="main-menu-two__call-and-btn-box">
                    <NewsletterForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div
          className={`stricky-header stricked-menu main-menu main-menu-two ${
            scroll ? "stricky-fixed" : ""
          }`}
        >
          <div className="sticky-header__content" />
          <nav className="main-menu main-menu-two">
            <div className="main-menu-two__wrapper">
              <div className="container">
                <div className="main-menu-two__wrapper-inner">
                  <div className="main-menu-two__left">
                    <div className="main-menu-two__logo">
                      <Link to="/">
                        <img src={LogoTwo} alt="Logo" />
                      </Link>
                    </div>
                    <div className="main-menu-two__main-menu-box">
                      <Link
                        to="#"
                        className="mobile-nav__toggler"
                        onClick={handleMobileMenu}
                      >
                        <i className="fa fa-bars" />
                      </Link>
                      <ul className="main-menu__list">
                        <MenuItems
                          parentMenu={parentMenu}
                          secondParentMenu={secondParentMenu}
                          activeMenu={activeMenu}
                        />
                      </ul>
                    </div>
                  </div>
                  <div className="main-menu-two__right">
                    <NewsletterForm />
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <MobileMenu
          handleMobileMenu={handleMobileMenu}
          handleSidebar={handleSidebar}
          isSidebar={isSidebar}
        />
      </header>
    </React.Fragment>
  );
};

export default HeaderStyleTwo;
