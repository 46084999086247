import React from "react";
import ServicesDetails from "../../components/Service/ServicesDetails";
import SabkibImg from "../../assets/images/services/services-details-client-img-1.jpg";
import BgImg from "../../assets/images/services/services-details-get-touch-bg-img.jpg";
import detailsImg from "../../assets/images/services/services-details-img-ltl.jpg";

const SolutionsMain = () => {
  const client = {
    image: SabkibImg,
    name: "Sakib al hasan",
    title: "Brand Ambassador",
    text: "Construction is an essential industry that the building and designing...",
    phone: "+888123456765",
    socials: [
      { icon: "icon-instagram", link: "/blog-details" },
      { icon: "icon-facebook-f", link: "/blog-details" },
      { icon: "icon-Vector", link: "/blog-details" },
      { icon: "icon-linkedin-in", link: "/blog-details" },
    ],
  };

  const services = [
    {
      name: "Less Than Truckload (LTL)",
      link: "/ltl",
      active: true,
    },
    {
      name: "Full Truckload (FLT)",
      link: "/ftl",
      active: false,
    },
    { name: "Expedited", link: "/expedited", active: false },
  ];


  const getTouch = {
    background: BgImg,
    title: "GET TOUCH",
    subTitle: "For fast service",
    icon: "icon-call",
    phone: "+888123456765",
  };

  const details = {
    title: "Less Than Truckload",
    text1:
      `We offer consolidated freight
        services that allow our customers to ship goods in smaller
        volumes efficiently and economically. This service is ideal for
        shipments that do not require the full capacity of a truck,
        optimizing costs and improving delivery times.`,
    image1: detailsImg,
    serviceBoxes: [
      {
        icon: "icon-airplane",
        title: "Rapid Delivery Services",
        link: "#",
        text: "Construction is an essential industry that involves building and designing...",
      },
      {
        icon: "icon-lift-truck",
        title: "Quick Move Logistics",
        link: "#",
        text: "Construction is an essential industry that involves building and designing...",
      },
    ],
    text2:
      `<strong>Cargo consolidation: </strong>Goods from different shippers are
        combined in one truck to optimize space and cut costs.<br/><br/>
        <strong>Shared routes: </strong>Multiple stops for pickups and deliveries at
        various locations.<br/><br/>
        <strong>Longer transit times: </strong>Compared to FTL, transit takes
        longer due to multiple stops.<br/><br/>
        <strong>Space-based rates: </strong>LTL costs are calculated based on
        weight, volume, and distance.<br/><br/>
        <strong>Ideal for medium shipments: </strong>Suitable for shipments that
        don’t require a full truck.`,
    prevLink: "/expedited",
    prevText: "Expedited",
    nextLink: "/ftl",
    nextText: "Full Truckload (FLT)",
  };
  const faqData = [
    {
      question: "How do you calculate the cost for LTL shipping?",
      answer:
        "LTL shipping costs are based on the weight, volume, and distance of your shipment. We consolidate different shipments to optimize space and save costs.",
    },
    {
      question: "Will my shipment take longer with LTL compared to other methods?",
      answer:
        "Yes, since LTL involves multiple pickups and deliveries along the route, transit times can be longer compared to Full Truckload (FTL) shipping.",
    },
    {
      question: "What size shipments are best for LTL?",
      answer:
        "LTL is ideal for medium-sized shipments that don't require the full capacity of a truck, helping you save money by sharing space with other shipments.",
    },
  ];

  return (
    <React.Fragment>
      <ServicesDetails
        client={client}
        services={services}
        getTouch={getTouch}
        details={details}
        faqData={faqData}
      />
    </React.Fragment>
  );
};

export default SolutionsMain;
