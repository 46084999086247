import React, { useEffect, useState } from "react";
import HomeTwoMain from "./HomeTwoMain";
import Footer from "../../components/Layout/Footer";
import HeaderStyleTwo from "../../components/Layout/Header/HeaderStyleTwo";
import bannerbg from "../../assets/images/backgrounds/page-header-bg.jpg";
import shapeImageUrl from "../../assets/images/shapes/cta-one-shape-1.png";
import CtaSection from "../../components/Common/CtaSection";
import BackToTop from "../../components/elements/BackToTop";

const HomeTwo = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="homestyle2">
      <React.Fragment>
        <HeaderStyleTwo />
        <HomeTwoMain />
        {/*cta-section */}
        <CtaSection
          className="cta-one"
          breadcrumbsImg={bannerbg}
          shapeImageUrl={shapeImageUrl}
          title="Need any help? Contact us!"
          text="Need help?"
          phoneNumber="(717) 885-3056"
        />
        {/*cta-section end */}
        <BackToTop scroll={isVisible} />
        <Footer />
      </React.Fragment>
    </div>
  );
};

export default HomeTwo;
