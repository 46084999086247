import {
  generateSubscribeTemplate,
  generateInternalSubscribeNotificationTemplate,
} from "../templates/subscribeTemplate";

const API_URL = process.env.REACT_APP_API_URL;

export const handleNewsletterSubscription = async (email) => {
  try {
    // Send confirmation email to subscriber
    const subscriberTemplate = generateSubscribeTemplate({
      clientEmail: email,
    });
    const response = await fetch(`${API_URL}/email/subscribe-notification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: email,
        ...subscriberTemplate,
      }),
    });

    // Send notification to company
    const internalTemplate = generateInternalSubscribeNotificationTemplate({
      clientEmail: email,
    });
    const internalResponse = await fetch(
      `${API_URL}/email/subscribe-confirmation`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...internalTemplate,
        }),
      }
    );

    if (response.ok && internalResponse.ok) {
      return { success: true };
    } else {
      throw new Error("Failed to send subscription emails");
    }
  } catch (error) {
    throw error;
  }
};
