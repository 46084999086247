import React from 'react';
import ServicesTwo from '../../components/HomeTwo/ServicesTwo';

const servicesData = [
    {
        icon: 'postbox', 
        title: 'Less Than Truckload (LTL)',
        text: 'Efficient and cost-effective service for smaller shipments, combining multiple shipments in one truck.',
        link: '/ltl',
        animation: 'fadeInLeft',
    },
    {
        icon: 'container',
        title: 'Full Truckload (FLT)',
        text: 'Dedicated trucking services for larger shipments that require the full space of a trailer.',
        link: '/ftl',
        animation: 'fadeInUp',
    },
    {
        icon: 'crane',
        title: 'Expedited',
        text: 'Fast and urgent shipping solutions to get your freight where it needs to be, as quickly as possible.',
        link: '/expedited',
        animation: 'fadeInRight',
    },
    
];

const Services = () => {
    return (
        <>
            <ServicesTwo services={servicesData} />
        </>
    );
};

export default Services;
