import React from "react";
import ServicesDetails from "../../components/Service/ServicesDetails";
import SabkibImg from "../../assets/images/services/services-details-client-img-1.jpg";
import BgImg from "../../assets/images/services/services-details-get-touch-bg-img.jpg";
import detailsImg from "../../assets/images/services/services-details-img-flt.jpg";

const DispatchMain = () => {
  const client = {
    image: SabkibImg,
    name: "Sakib al hasan",
    title: "Brand Ambassador",
    text: "Construction is an essential industry that the building and designing...",
    phone: "+888123456765",
    socials: [
      { icon: "icon-instagram", link: "/blog-details" },
      { icon: "icon-facebook-f", link: "/blog-details" },
      { icon: "icon-Vector", link: "/blog-details" },
      { icon: "icon-linkedin-in", link: "/blog-details" },
    ],
  };

  const services = [
    {
      name: "Less Than Truckload (LTL)",
      link: "/ltl",
      active: false,
    },
    {
      name: "Full Truckload (FLT)",
      link: "/ftl",
      active: true,
    },
    { name: "Expedited", link: "/expedited", active: false },
  ];


  const getTouch = {
    background: BgImg,
    title: "GET TOUCH",
    subTitle: "For fast service",
    icon: "icon-call",
    phone: "+888123456765",
  };

  const details = {
    title: "Full Truckload",
    text1:
      `We provide full load services, where the customer can use a
      truck in its entirety for their shipments. This service is perfect
      for large volumes of merchandise that require exclusive
      attention and direct transit to the final destination.`,
    image1: detailsImg,
    serviceBoxes: [
      {
        icon: "icon-airplane",
        title: "Rapid Delivery Services",
        link: "#",
        text: "Construction is an essential industry that involves building and designing...",
      },
      {
        icon: "icon-lift-truck",
        title: "Quick Move Logistics",
        link: "#",
        text: "Construction is an essential industry that involves building and designing...",
      },
    ],
    text2:
      `Specialized dedicated trailer programs available throughout
        the United States.<br/><br/>
        Expedited services for transport teams.<br/><br/>
        Partial services that allow your freight to be the last loaded
        and the first unloaded with OTR transit times.<br/><br/>
        Lift gates at the shipper and consignee locations.<br/><br/>
        Driver assistance during loading and unloading.<br/><br/>
        Availability of box trucks or straight trucks.<br/><br/>
        Performance reviews required for carriers.`,
    prevLink: "/ltl",
    prevText: "Less Than Truckload (LTL)",
    nextLink: "/expedited",
    nextText: "Expedited",
  };

  const faqData = [
    {
      question: "When should I choose Full Truckload over LTL?",
      answer:
        "Full Truckload is best for large shipments that need exclusive use of a truck and direct transit to the final destination without sharing space with other goods.",
    },
    {
      question: "Do you offer lift gate services for FTL shipments?",
      answer:
        "Yes, we provide lift gate services at both the shipper and consignee locations to assist with loading and unloading heavier freight.",
    },
    {
      question: "Can I request expedited services with Full Truckload?",
      answer:
        "Absolutely! We offer expedited Full Truckload options that prioritize your shipment to ensure faster delivery times.",
    },
  ];

  return (
    <React.Fragment>
      <ServicesDetails
        client={client}
        services={services}
        getTouch={getTouch}
        details={details}
        faqData={faqData}
      />
    </React.Fragment>
  );
};

export default DispatchMain;
