import React, { useState } from "react";
import bannerbg from "../../assets/images/resources/cta-one-bg-img.jpg";
import shapeImageUrl from "../../assets/images/shapes/cta-one-shape-1.png";
import ContactMainImg from "../../assets/images/resources/contact-two-img-1.jpg";
import CtaSection from "../../components/Common/CtaSection";
import GoogleMap from "../home-2/GoogleMap";
import useSendEmail from "../../hooks/useSendEmail";
import useStatusMessage from "../../hooks/useStatusMessage";

const CarrierServiceMain = () => {
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [carrierMC, setCarrierMC] = useState("");
  const [message, setMessage] = useState("");
  const [truckCount, setTruckCount] = useState(1);
  const [status, setStatus] = useStatusMessage();
  const { sendEmail, isLoading } = useSendEmail();

  const handleClientNameChange = (e) => {
    setClientName(e.target.value);
  };

  const handleClientEmailChange = (e) => {
    setClientEmail(e.target.value);
  };

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleCarrierMCChange = (e) => {
    setCarrierMC(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleTruckCountChange = (e) => {
    const value = Math.min(Math.max(parseInt(e.target.value) || 1, 1), 100);
    setTruckCount(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!clientName || !companyName || !phoneNumber || !carrierMC || !message) {
      setStatus({
        success: false,
        message: "Please fill in all required fields.",
      });
      return;
    }

    setStatus({ success: null, message: "" });

    try {
      const result = await sendEmail(
        {
          clientName,
          companyName,
          clientEmail,
          phoneNumber,
          carrierMC,
          truckCount,
          message,
        },
        "carrier"
      );

      if (result.success) {
        setStatus({
          success: true,
          message: "Application submitted successfully!",
        });
        // Reset form
        setClientName("");
        setCompanyName("");
        setClientEmail("");
        setPhoneNumber("");
        setCarrierMC("");
        setMessage("");
        setTruckCount(1);
      } else {
        throw new Error(result.error || "Failed to submit application");
      }
    } catch (err) {
      setStatus({
        success: false,
        message:
          err.message ||
          "Failed to submit application. Please try again later.",
      });
    }
  };

  return (
    <React.Fragment>
      {/* Contact Two with Carrier Service Form */}
      <section className="contact-two contact-two-custom-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-7">
              <div className="contact-two__left">
                <div className="section-title text-left">
                  <div className="section-title__tagline-box">
                    <span className="section-title__tagline">
                      Carrier Service
                    </span>
                  </div>
                  <h2 className="section-title__title">
                    Join Our Carrier Network
                  </h2>
                </div>
                {status.message && (
                  <div
                    className={`alert ${
                      status.success ? "alert-success" : "alert-danger"
                    }`}
                    style={{
                      padding: "15px",
                      borderRadius: "5px",
                      backgroundColor: status.success ? "#d4edda" : "#f8d7da",
                      color: status.success ? "#155724" : "#721c24",
                      marginBottom: "20px",
                    }}
                  >
                    {status.message}
                  </div>
                )}

                <form
                  onSubmit={handleSubmit}
                  className="contact-form-validated contact-two__form"
                >
                  <div className="contact-two__input-container">
                    <div className="contact-two__input-box">
                      <input
                        type="text"
                        id="clientName"
                        value={clientName}
                        onChange={handleClientNameChange}
                        placeholder="Enter your full name"
                        required
                      />
                    </div>

                    <div className="contact-two__input-box">
                      <input
                        type="text"
                        id="companyName"
                        value={companyName}
                        onChange={handleCompanyNameChange}
                        placeholder="Enter company name"
                        required
                      />
                    </div>

                    <div className="contact-two__input-box">
                      <input
                        type="email"
                        id="clientEmail"
                        value={clientEmail}
                        onChange={handleClientEmailChange}
                        placeholder="Enter your email"
                        required
                      />
                    </div>

                    <div className="contact-two__input-box">
                      <input
                        type="text"
                        id="phoneNumber"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        placeholder="Enter phone number"
                        required
                      />
                    </div>

                    <div className="contact-two__input-box">
                      <input
                        type="text"
                        id="carrierMC"
                        value={carrierMC}
                        onChange={handleCarrierMCChange}
                        placeholder="Enter Carrier MC#"
                        required
                      />
                    </div>
                    <h5 className="text-sm font-medium text-gray-600 mb-2">
                      Number of Trucks
                    </h5>
                    <div className="contact-two__input-box">
                      <input
                        type="number"
                        id="truckCount"
                        min="1"
                        max="100"
                        value={truckCount}
                        onChange={handleTruckCountChange}
                        placeholder="Enter number of trucks"
                        required
                      />
                    </div>

                    <div className="contact-two__input-box text-message-box">
                      <textarea
                        name="message"
                        value={message}
                        onChange={handleMessageChange}
                        placeholder="Additional information"
                        required
                      />
                    </div>

                    <div className="contact-two__btn-box">
                      <button
                        type="submit"
                        className="thm-btn contact-two__btn"
                        disabled={isLoading}
                      >
                        {isLoading ? "Submitting..." : "Submit Application"}
                        <span />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="contact-two__right">
                <div className="contact-two__img">
                  <img src={ContactMainImg} alt="Carrier Service" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*Google Map Start*/}
      <GoogleMap />
      {/*Google Map End*/}

      {/*cta-section */}
      <CtaSection
        breadcrumbsImg={bannerbg}
        shapeImageUrl={shapeImageUrl}
        title="Need any help? Contact us!"
        text="Need help?"
        phoneNumber="(717) 885-3056"
      />
      {/*cta-section end */}
    </React.Fragment>
  );
};

export default CarrierServiceMain;
