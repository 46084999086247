import React from "react";
import { Link } from "react-router-dom";

const MenuItems = (props) => {
  const { parentMenu, activeMenu } = props;

  return (
    <React.Fragment>
      <li className={parentMenu === "Home" ? "current-menu-item" : ""}>
        <Link to="/" className={activeMenu === "/" ? "active-menu" : ""}>
          Home
        </Link>
      </li>
      <li
        className={
          parentMenu === "page" ? "dropdown current-menu-item" : "dropdown"
        }
      >
        <Link to="#">Company</Link>
        <ul className="sub-menu">
          <li className={parentMenu === "about" ? "current-menu-item" : ""}>
            <Link
              to="/about"
              className={activeMenu === "/about" ? "active-menu" : ""}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="/team"
              className={activeMenu === "/team" ? "active-menu" : ""}
            >
              Our Team
            </Link>
          </li>
          <li className={parentMenu === "contact" ? "current-menu-item" : ""}>
            <Link
              to="/contact"
              className={activeMenu === "/contact" ? "active-menu" : ""}
            >
              Contact
            </Link>
          </li>
        </ul>
      </li>
      <li
        className={
          parentMenu === "Services" ? "dropdown current-menu-item" : "dropdown"
        }
      >
        <Link to="#">Services</Link>
        <ul className="sub-menu">
          <li>
            <Link
              to="/services"
              className={activeMenu === "/services" ? "active-menu" : ""}
            >
              Services Overview
            </Link>
          </li>
          <li>
            <Link
              to="/ltl"
              className={activeMenu === "/ltl" ? "active-menu" : ""}
            >
              Less Than Truckload (LTL)
            </Link>
          </li>
          <li>
            <Link
              to="/ftl"
              className={activeMenu === "/ftl" ? "active-menu" : ""}
            >
              Full Truckload (FLT)
            </Link>
          </li>
          <li>
            <Link
              to="/expedited"
              className={activeMenu === "/expedited" ? "active-menu" : ""}
            >
              Expedited
            </Link>
          </li>
        </ul>
      </li>
      <li
        className={parentMenu === "carrier-service" ? "current-menu-item" : ""}
      >
        <Link
          to="/carrier-service"
          className={activeMenu === "/carrier-service" ? "active-menu" : ""}
        >
          Carrier Service
        </Link>
      </li>
      <li className={parentMenu === "get-a-quote" ? "current-menu-item" : ""}>
        <Link
          to="/get-a-quote"
          className={activeMenu === "/get-a-quote" ? "active-menu" : ""}
        >
          Get a Quote
        </Link>
      </li>
    </React.Fragment>
  );
};

export default MenuItems;
