import React from "react";
import ServicesDetails from "../../components/Service/ServicesDetails";
import BgImg from "../../assets/images/services/services-details-get-touch-bg-img.jpg";
import detailsImg from "../../assets/images/services/services-details-img-expedited.jpg";

const LogisticsMain = () => {


  const services = [
    {
      name: "Less Than Truckload (LTL)",
      link: "/ltl",
      active: false,
    },
    {
      name: "Full Truckload (FLT)",
      link: "/ftl",
      active: false,
    },
    { name: "Expedited", link: "/expedited", active: true },
  ];

  const getTouch = {
    background: BgImg,
    title: "GET TOUCH",
    subTitle: "For fast service",
    icon: "icon-call",
    phone: "+888123456765",
  };

  const details = {
    title: "Expedited",
    text1: `Service that moves goods quickly through the supply chain,
      offering priority handling and quicker processing times to
      ensure prompt delivery. The service usually comes at a higher
      cost than standard shipping but guarantees faster transit times.`,
    image1: detailsImg,
    serviceBoxes: [
      {
        icon: "icon-airplane",
        title: "Rapid Delivery Services",
        link: "#",
        text: "Construction is an essential industry that involves building and designing...",
      },
      {
        icon: "icon-lift-truck",
        title: "Quick Move Logistics",
        link: "#",
        text: "Construction is an essential industry that involves building and designing...",
      },
    ],
    text2:
      "",
    prevLink: "/ftl",
    prevText: "Full Truckload (FLT)",
    nextLink: "/ltl",
    nextText: "Less Than Truckload (LTL)",
  };

  const faqData = [
    {
      question: "How fast can I expect my goods to arrive with expedited shipping?",
      answer:
        "Expedited shipping ensures your goods are prioritized, often arriving in less than half the time of standard shipping, depending on the route and distance.",
    },
    {
      question: "Is expedited shipping more expensive than standard options?",
      answer:
        "Yes, expedited shipping typically comes with a higher cost due to the faster transit times and priority handling of your goods.",
    },
    {
      question: "What types of goods are best suited for expedited shipping?",
      answer:
        "Expedited shipping is perfect for time-sensitive goods or products that need to meet strict deadlines, such as perishable items or high-priority materials.",
    },
  ];

  return (
    <React.Fragment>
      <ServicesDetails
        services={services}
        getTouch={getTouch}
        details={details}
        faqData={faqData}
      />
    </React.Fragment>
  );
};

export default LogisticsMain;
