import React from "react";
import AboutOne from "../../components/About/AboutOne";
import WhyAreWe from "../../components/About/WhyAreWe";
import image1 from "../../assets/images/resources/why-are-we-img-1.jpg";
import image2 from "../../assets/images/resources/why-are-we-img-2.jpg";

const AboutMain = () => {
  const points = [
    {
      iconClass: "why-are-we__icon",
      arrowIcon: "",
      icon: "",
      heading: "Mission",
      text: `We provide comprehensive transport and logistics solutions, focusing
                on efficiency, reliability, and adaptability to meet each client's unique
                needs. We leverage technology to continuously improve, ensuring a
                real-time flow of information and striving to exceed customer
                expectations through operational excellence.`,
    },
    {
      iconClass: "why-are-we__icon-2",
      arrowIcon: "",
      icon: "",
      heading: "Vision",
      text: `Our goal is to become a leading company in the transport
                and logistics sector, operating autonomously and efficiently.
                We aim to expand by opening new offices and managing a fleet
                of at least 50 trucks, all while integrating cutting-edge
                technology to streamline operations and ensure a seamless
                flow of information, delivering world-class service that
                exceeds client expectations.`,
    },
    {
      iconClass: "why-are-we__icon-2",
      arrowIcon: "",
      icon: "",
      heading: "Values",
      text: `
                <strong>Integrity:</strong> We maintain ethical conduct in all our interactions, operating
                with transparency and honesty. Integrity is the foundation on which we
                build our reputation and the trust of our customers.<br /><br />
                <strong>Responsibility:</strong> We act with a high sense of responsibility in all our
                operations, fulfilling the commitments made and ensuring that each
                shipment reaches its destination in a timely and safe manner.<br /><br />
                <strong>Respect:</strong> We foster a work environment based on mutual respect, both
                within our organization and in our relationships with clients and
                business partners. We believe in the importance of building long-lasting
                relationships based on trust and collaboration.
            `,
    },
  ];

  return (
    <React.Fragment>
      <AboutOne />
      <WhyAreWe
        imageSrc1={image1}
        imageSrc2={image2}
        tagline="Why are we best"
        title="Efficiency at its best with our logistics services"
        points={points}
        year="Since 2024"
        yearDescription="Logistic service involves the planning, implementation, and control of the efficient and effective movement and storage"
      />
    </React.Fragment>
  );
};

export default AboutMain;
