import React from "react";
import TeamMember from "../../components/Team/TeamMember";
import Santiago from "../../assets/images/team/team-1-1.jpg";
import Yanni from "../../assets/images/team/team-1-2.jpg";
import Daiana from "../../assets/images/team/team-1-3.jpg";
import Chanilda from "../../assets/images/team/team-1-4.jpg";

const TeamMain = () => {
  // Example team member data
  const teamMembers = [
    {
      id: 1,
      image: Santiago,
      name: "Santiago Montás",
      position: "OPERATIONS/ CEO",
      socialLinks: [
        { url: "#", iconClass: "icon-instagram" },
        { url: "#", iconClass: "icon-linkedin-in" },
        { url: "#", iconClass: "icon-Vector" },
        { url: "#", iconClass: "icon-facebook-f" },
      ],
      link: "/team-details",
    },
    {
      id: 2,
      image: Yanni,
      name: "Yanni Caba",
      position: "VP/ VICE PRESIDENT",
      socialLinks: [
        { url: "#", iconClass: "icon-instagram" },
        { url: "#", iconClass: "icon-linkedin-in" },
        { url: "#", iconClass: "icon-Vector" },
        { url: "#", iconClass: "icon-facebook-f" },
      ],
      link: "/team-details",
    },
    {
      id: 3,
      image: Chanilda,
      name: "Chanilda Martinez",
      position: "GENERAL MANAGER",
      socialLinks: [
        { url: "#", iconClass: "icon-instagram" },
        { url: "#", iconClass: "icon-linkedin-in" },
        { url: "#", iconClass: "icon-Vector" },
        { url: "#", iconClass: "icon-facebook-f" },
      ],
      link: "/team-details",
    },
    {
      id: 4,
      image: Daiana,
      name: "Daiana Castillo",
      position: "ACCOUNTING/ ACCG",
      socialLinks: [
        { url: "#", iconClass: "icon-instagram" },
        { url: "#", iconClass: "icon-linkedin-in" },
        { url: "#", iconClass: "icon-Vector" },
        { url: "#", iconClass: "icon-facebook-f" },
      ],
      link: "/team-details",
    },
  ];

  return (
    <React.Fragment>
      <section className="team-page">
        <div className="container">
          <div className="row">
            {teamMembers.map((member) => (
              <div key={member.id} className="col-xl-4 col-lg-6 col-md-6">
                <TeamMember {...member} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default TeamMain;
