import React, { useState } from "react";
import { Link } from "react-router-dom";
import { handleNewsletterSubscription } from "../../../utils/newsletterSubscription";

// Import default images
import footerLogo2 from "../../../assets/images/resources/footer-logo-2.png";

const Footer = ({
  footerLogo = footerLogo2,
  footerClass = "site-footer",
  aboutText = "ShipRight Transit is dedicated to providing reliable and efficient shipping solutions to meet all your logistics needs.",
  contactInfo = [
    {
      icon: "icon-envolop email-icon",
      text: "info@shiprighttransit.com",
      link: "mailto:info@shiprighttransit.com",
    },
    {
      icon: "icon-location location-icon",
      text: "247N Newberry St York PA 17401",
    },
    {
      icon: "fab fa-instagram",
      text: "@shipright_transit",
      link: "https://www.instagram.com/shipright_transit/profilecard/?igsh=OW44MW95MjN0cnNi",
    },
    {
      icon: "icon-call phone-call",
      text: "(717) 885-3056",
      link: "tel:17178853056",
    },
  ],
  companyLinks = [
    { text: "About Us", link: "/about" },
    { text: "Our Team", link: "/team" },
    { text: "Contact us", link: "/contact" },
  ],
  services = [
    { name: "Less Than Truckload (LTL)", link: "/ltl" },
    { name: "Full Truckload (FLT)", link: "/ftl" },
    { name: "Expedited", link: "/expedited" },
  ],
  newsletterUrl = "MC_FORM_URL",
  newsletterText = "Stay updated with the latest news and offers from ShipRight Transit.",
  bottomMenu = [{ text: "Contact Us", link: "/contact" }],
  copyrightText = "© ShipRight Transit 2024 | All Rights Reserved | Powered By: TopUs",
}) => {
  const [subscriptionStatus, setSubscriptionStatus] = useState({
    show: false,
    type: null,
    message: "",
  });

  const handleSubscribe = async (e) => {
    e.preventDefault();
    const email = e.target.EMAIL.value;

    try {
      const result = await handleNewsletterSubscription(email);

      if (result.success) {
        setSubscriptionStatus({
          show: true,
          type: "success",
          message: "✓ Successfully subscribed to our newsletter!",
        });
        e.target.reset();

        // Hide success message after 5 seconds
        setTimeout(() => {
          setSubscriptionStatus({ show: false, type: null, message: "" });
        }, 5000);
      }
    } catch (error) {
      setSubscriptionStatus({
        show: true,
        type: "error",
        message: "× Error sending subscription. Please try again.",
      });

      // Hide error message after 4 seconds
      setTimeout(() => {
        setSubscriptionStatus({ show: false, type: null, message: "" });
      }, 4000);
    }
  };

  return (
    <>
      {/*Site Footer Start*/}
      <footer className={footerClass}>
        <div className="site-footer__top">
          <div className="container">
            <div className="site-footer__top-inner">
              <div className="row">
                <div
                  className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="100ms"
                >
                  <div className="footer-widget__column footer-widget__about">
                    <div className="footer-widget__logo">
                      <Link to="/">
                        <img src={footerLogo} alt="Logo" />
                      </Link>
                    </div>
                    <p className="footer-widget__about-text">{aboutText}</p>
                    <ul className="footer-widget__about-contact list-unstyled">
                      {contactInfo.map((info, index) => (
                        <li key={index}>
                          <div className="icon">
                            <span className={info.icon} />
                          </div>
                          <p>
                            {info.link ? (
                              <a href={info.link}>{info.text}</a>
                            ) : (
                              info.text
                            )}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="200ms"
                >
                  <div className="footer-widget__column footer-widget__company">
                    <div className="footer-widget__title-box">
                      <h3 className="footer-widget__title">About Company</h3>
                    </div>
                    <ul className="footer-widget__company-list list-unstyled">
                      {companyLinks.map((link, index) => (
                        <li key={index}>
                          <Link to={link.link}>{link.text}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <div className="footer-widget__column footer-widget__services">
                    <div className="footer-widget__title-box">
                      <h3 className="footer-widget__title">Services</h3>
                    </div>
                    <ul className="footer-widget__services-list list-unstyled">
                      {services.map((service, index) => (
                        <li key={index}>
                          <Link to={service.link}>
                            <span className="icon-angle-left" />
                            {service.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="400ms"
                >
                  <div className="footer-widget__column footer-widget__newsletter">
                    <div className="footer-widget__title-box">
                      <h3 className="footer-widget__title">Newsletter</h3>
                    </div>
                    <p className="footer-widget__newsletter-text">
                      {newsletterText}
                    </p>
                    <form
                      className="footer-widget__newsletter-form"
                      onSubmit={handleSubscribe}
                    >
                      <div className="footer-widget__newsletter-form-input-box">
                        <input
                          type="email"
                          placeholder="Your e-mail"
                          name="EMAIL"
                          required
                          className="w-full"
                        />
                        <button
                          type="submit"
                          className="footer-widget__newsletter-btn"
                          disabled={subscriptionStatus.show}
                        >
                          <span className="icon-paper-plane" />
                        </button>
                      </div>
                      {subscriptionStatus.show && (
                        <div
                          className={`
                                                    text-sm mt-2 font-medium
                                                    ${
                                                      subscriptionStatus.type ===
                                                      "success"
                                                        ? "text-green-500"
                                                        : "text-red-500"
                                                    }
                                                    transition-opacity duration-300
                                                `}
                          role="alert"
                        >
                          {subscriptionStatus.message}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer__bottom">
          <div className="container">
            <div className="site-footer__bottom-inner">
              <p className="site-footer__bottom-text">{copyrightText}</p>
              <ul className="list-unstyled site-footer__bottom-menu">
                {bottomMenu.map((item, index) => (
                  <li key={index}>
                    <Link to={item.link}>{item.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {/*Site Footer End*/}
    </>
  );
};

export default Footer;
