import React, { useState } from "react";
import CtaSection from "../../components/Common/CtaSection";
import bannerbg from "../../assets/images/resources/cta-one-bg-img.jpg";
import shapeImageUrl from "../../assets/images/shapes/cta-one-shape-1.png";
import ContactMainImg from "../../assets/images/resources/contact-two-img-1.jpg";
import GoogleMap from "../../pages/home-2/GoogleMap";
import useSendEmail from "../../hooks/useSendEmail";
import useStatusMessage from "../../hooks/useStatusMessage";

const ContactMain = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useStatusMessage();

  const { sendEmail } = useSendEmail();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus({ success: null, message: "" });

    try {
      const result = await sendEmail(formData, "contact");
      if (result.success) {
        setStatus({
          success: true,
          message: "Message sent successfully!",
        });
        setFormData({ name: "", email: "", subject: "", message: "" });
      } else {
        throw new Error(result.error || "Failed to send message");
      }
    } catch (err) {
      setStatus({
        success: false,
        message: err.message || "Failed to send message",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      {/*Contact One Start*/}
      <section className="contact-one">
        <div className="container">
          <div className="section-title text-center">
            <div className="section-title__tagline-box">
              <span className="section-title__tagline">Contact us</span>
            </div>
            <h2 className="section-title__title">Get in Touch With Us</h2>
          </div>
          <div className="contact-one__inner">
            <ul className="contact-one__contact-list list-unstyled">
              <li>
                <div className="icon">
                  <span className="icon-call" />
                </div>
                <div className="content">
                  <h3>Lets Talk</h3>
                  <p>
                    Phone number:{" "}
                    <a href="tel:32566800890">+32566 - 800 - 890</a>
                  </p>
                </div>
              </li>
              <li>
                <div className="icon">
                  <span className="icon-location location-icon" />
                </div>
                <div className="content">
                  <h3>Address</h3>
                  <p>
                    247N Newberry St
                    <br /> York PA 17401
                  </p>
                </div>
              </li>
              <li>
                <div className="icon">
                  <span className="icon-envolop email-icon" />
                </div>
                <div className="content">
                  <h3>Send email</h3>
                  <p>
                    <a href="mailto:info@shiprighttransit.com">
                      info@shiprighttransit.com
                    </a>
                  </p>
                  <p>
                    <a href="mailto:smontas@shiprighttransit.com">
                      smontas@shiprighttransit.com
                    </a>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/*Contact One End*/}
      {/*Contact Two Start*/}
      <section className="contact-two">
        <div className="container">
          <div className="row">
            <div className="col-xl-7">
              <div className="contact-two__left">
                <div className="section-title text-left">
                  <div className="section-title__tagline-box">
                    <span className="section-title__tagline">Contact</span>
                  </div>
                  <h2 className="section-title__title">Send your inquiry</h2>
                </div>
                {status.message && (
                  <div
                    className={`alert ${
                      status.success ? "alert-success" : "alert-danger"
                    } mt-3`}
                    style={{
                      padding: "15px",
                      borderRadius: "5px",
                      backgroundColor: status.success ? "#d4edda" : "#f8d7da",
                      color: status.success ? "#155724" : "#721c24",
                      marginTop: "20px",
                    }}
                  >
                    {status.message}
                  </div>
                )}
                <form
                  className="contact-form-validated contact-two__form"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <div className="row">
                    <div className="col-xl-6 col-lg-6">
                      <div className="contact-two__input-box">
                        <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          placeholder="Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                      <div className="contact-two__input-box">
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          placeholder="E-mail"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                      <div className="contact-two__input-box">
                        <input
                          type="text"
                          name="subject"
                          value={formData.subject}
                          onChange={handleInputChange}
                          placeholder="Subject"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="contact-two__input-box text-message-box">
                        <textarea
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          placeholder="Message"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="contact-two__btn-box">
                        <button
                          type="submit"
                          className="thm-btn contact-two__btn"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Sending..." : "Submit Now"}
                          <span />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="contact-two__right">
                <div className="contact-two__img">
                  <img src={ContactMainImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Contact Two End*/}
      {/*Google Map Start*/}
      <GoogleMap />
      {/*Google Map End*/}

      {/*cta-section */}
      <CtaSection
        breadcrumbsImg={bannerbg}
        shapeImageUrl={shapeImageUrl}
        title="Need any help? Contact us!"
        text="Need help?"
        phoneNumber="(717) 885-3056"
      />
      {/*cta-section end */}
    </React.Fragment>
  );
};

export default ContactMain;
