import React, { useEffect, useState } from "react";
import BackToTop from "../../components/elements/BackToTop";
import Header from "../../components/Layout/Header";
import navImg1 from "../../assets/images/resources/logo-1.png";
import Footer from "../../components/Layout/Footer";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import bannerbg from "../../assets/images/backgrounds/page-header-bg.jpg";
import LogisticsMain from "./LogisticsMain";
import CtaSection from "../../components/Common/CtaSection";
import shapeImageUrl from "../../assets/images/shapes/cta-one-shape-1.png";

// Breadcrumbs Background Image

const Logistics = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <Header navImg={navImg1} parentMenu="services" activeMenu="/services" />
      {/* SiteBreadcrumb */}
      <SiteBreadcrumb
        pageTitle="Expedited Service"
        pageName="Expedited"
        breadcrumbsImg={bannerbg}
      />
      {/* SiteBreadcrumb */}
      <LogisticsMain />
      {/*cta-section */}
      <CtaSection
        className="cta-one cta-two" // Custom class name
        breadcrumbsImg={bannerbg}
        shapeImageUrl={shapeImageUrl}
        title="Need any help? Contact us!"
        text="Need help?"
        phoneNumber="(717) 885-3056"
      />
      {/*cta-section end */}
      <BackToTop scroll={isVisible} />
      <Footer />
    </React.Fragment>
  );
};

export default Logistics;
