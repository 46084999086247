import { useState, useEffect } from "react";

const useStatusMessage = (timeout = 5000) => {
  const [status, setStatus] = useState({ success: null, message: "" });

  useEffect(() => {
    let timer;
    if (status.message) {
      timer = setTimeout(() => {
        setStatus({ success: null, message: "" });
      }, timeout);
    }
    return () => clearTimeout(timer);
  }, [status.message, timeout]);

  return [status, setStatus];
};

export default useStatusMessage;
