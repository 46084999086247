import React from 'react';
import { Link } from 'react-router-dom';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const swiperOptions = {
  modules: [Autoplay, Pagination, Navigation],
  slidesPerView: 2,
  spaceBetween: 30,
  speed: 2000,
  autoplay: {
    delay: 9000,
    disableOnInteraction: false,
  },
  loop: true,
  navigation: {
    nextEl: '.swiper-button-prev1',
    prevEl: '.swiper-button-next1',
  },
  pagination: {
    el: '.swiper-dot-style1',
    type: 'bullets',
    clickable: true,
  },
  breakpoints: {
    0: {
      spaceBetween: 0,
      slidesPerView: 1,
    },
    375: {
      spaceBetween: 0,
      slidesPerView: 1,
    },
    575: {
      spaceBetween: 0,
      slidesPerView: 1,
    },
    768: {
      spaceBetween: 30,
      slidesPerView: 1,
    },
    992: {
      spaceBetween: 30,
      slidesPerView: 2,
    },
    1200: {
      spaceBetween: 30,
      slidesPerView: 3,
    },
    1320: {
      spaceBetween: 30,
      slidesPerView: 3,
    },
  },
};

export default function ServiceSlider() {
  const services = [
    { iconClass: 'icon-postbox', title: 'Less Than Truckload (LTL)', link: '/ltl' },
    { iconClass: 'icon-container', title: 'Full Truckload (FTL)', link: '/ftl' },
    { iconClass: 'icon-shipping', title: 'Expedited Shipping', link: '/expedited' },
  ];

  return (
    <Swiper {...swiperOptions} className="thm-swiper__slider swiper-container">
      <div className="swiper-wrapper">
        {services.map((service, index) => (
          <SwiperSlide key={index} className="swiper-slide">
            <div className="services-carousel__single">
              <div className="services-one__icon">
                <span className={service.iconClass} />
              </div>
              <h3 className="services-one__title">
                <Link to={service.link}>
                  {service.title}
                </Link>
              </h3>
              <div className="services-one__btn-box">
                <Link to={service.link} className="thm-btn services-one__btn">
                  Read more
                  <span />
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </div>
    </Swiper>
  );
}
