import React from 'react';

const GoogleMap = () => {
    return (
        <section className="google-map-one">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.9759293448187!2d-76.73915242343149!3d39.964290871515956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c88d7eacd1b843%3A0x406f64208d48e04d!2sShipRight%20Transit!5e0!3m2!1sen!2sdo!4v1727025993668!5m2!1sen!2sdo"
                className="google-map__one"
                allowFullScreen
                loading="lazy"
                title="ShipRight Transit Location"
            ></iframe>
        </section>
    );
};

export default GoogleMap;
